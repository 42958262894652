import { EventContentArg } from '@fullcalendar/react';
import { Card, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { DateClickArg } from '@fullcalendar/interaction';
import { useModal } from '@ebay/nice-modal-react';

import CustomCalendarTimeline, {
    CustomCalendarTimelineProps,
    FullCalendarTimelineViews,
} from '../../../components/CustomCalendarTimeline';
import { classNames } from '../../../helpers';
import genericMessages from '../../../i18n/genericMessages';
import planningsMessages, { planningsCaptionOperatorMessagesMap } from '../../../i18n/planningsMessages';
import { OperatorPlanning, OperatorShiftStatus, TaskGroupStatus } from '../../../queries/api/types';
import { PlanningsTabs } from './ManagementPlannings';
import OperatorPlanningFormatter, { MergedEvent } from './OperatorPlanningFormatter';
import { taskGroupCaptionMessagesMap } from '../../../i18n/taskGroupMessages';
import FormattedDuration from '../../../components/FormattedDuration';
import OperatorAbsenceAddModal from '../../../components/modals/OperatorAbsenceAddModal';

export interface PlanningsOperatorsProps {
    activeDate: Dayjs;
    activeView: FullCalendarTimelineViews;
    operatorsPlanning: OperatorPlanning[] | undefined;
}

const PlanningsOperators: VFC<PlanningsOperatorsProps> = ({ activeDate, activeView, operatorsPlanning }) => {
    const { formatMessage, formatNumber } = useIntl();
    const { tab } = useParams<{ tab: PlanningsTabs }>();
    const operatorAbsenceAddModal = useModal(OperatorAbsenceAddModal);

    const onClickDate: CustomCalendarTimelineProps['dateClick'] = useCallback(
        (arg: DateClickArg) => {
            const { resource, dateStr } = arg;
            const operatorId = resource?._resource.id;
            if (operatorId) {
                const initialDateRange = [dayjs(dateStr).startOf('day'), dayjs(dateStr).endOf('day')];
                operatorAbsenceAddModal.show({ operatorId, initialDateRange });
            }
        },
        [operatorAbsenceAddModal]
    );

    if (!operatorsPlanning) return null;

    const resourceAreaColumns = [
        {
            field: 'operator',
            headerContent: formatMessage(genericMessages.operator),
            cellContent: (arg: any) => (
                <span className="text-base font-semibold text-blue">
                    {arg.fieldValue.firstName} {arg.fieldValue.lastName}
                </span>
            ),
        },
        {
            field: 'operator',
            headerContent: formatMessage(planningsMessages.accumulatedHours),
            cellContent: (arg: any) => {
                const { timeWorkedOverSemester, profileWorkDuration } = arg.fieldValue as OperatorPlanning['operator'];

                if (!profileWorkDuration || !timeWorkedOverSemester) return null;

                const timeWorkedOverSemesterInHours = (timeWorkedOverSemester ?? 0) / 60;

                const isBgGreen = timeWorkedOverSemesterInHours < profileWorkDuration;
                const isBgOrange = false; // TODO waiting design infos
                const isBgRed = timeWorkedOverSemesterInHours > profileWorkDuration;

                return (
                    <div className="flex justify-start items-center">
                        <div
                            className={classNames(
                                'text-xs font-semibold text-white px-1 leading-5.5',
                                isBgGreen && 'bg-green',
                                isBgOrange && 'bg-orange',
                                isBgRed && 'bg-red'
                            )}
                        >
                            {formatMessage(genericMessages.durationInHour, {
                                duration: formatNumber(timeWorkedOverSemesterInHours, { maximumFractionDigits: 1 }),
                                unitDisplay: 'short',
                            })}
                        </div>

                        <div className="text-xs font-semibold text-grey-7 leading-5.5 bg-blueish px-1">
                            {formatMessage(genericMessages.durationInHour, {
                                duration: formatNumber(profileWorkDuration, {
                                    maximumFractionDigits: 1,
                                }),
                                unitDisplay: 'short',
                            })}
                        </div>
                    </div>
                );
            },
        },
    ];

    const resources = operatorsPlanning?.map((operatorPlanning) => ({
        ...operatorPlanning,
        id: operatorPlanning.operator?.id,
        title: operatorPlanning.operator?.firstName,
    }));

    const renderEventContent = ({ event: { extendedProps } }: EventContentArg) => {
        const tooltipTitle = (mergedEvent: any) => {
            const { status, date, workedTime, type } = mergedEvent;
            return (
                <>
                    {status && (
                        <>
                            {status === TaskGroupStatus.readyToStart ? (
                                <span className="block text-sm text-black font-bold">
                                    {dayjs().isAfter(date, 'day')
                                        ? formatMessage(
                                              planningsCaptionOperatorMessagesMap.get(OperatorShiftStatus.unworked)!
                                          )
                                        : formatMessage(taskGroupCaptionMessagesMap.get(status)!)}
                                </span>
                            ) : (
                                <span className="block text-sm text-black font-bold">
                                    {formatMessage(taskGroupCaptionMessagesMap.get(status)!)}
                                </span>
                            )}
                        </>
                    )}
                    {type && (
                        <>
                            <span className="block text-sm text-black font-bold">
                                {formatMessage(
                                    planningsCaptionOperatorMessagesMap.get(OperatorShiftStatus.unavailable)!
                                )}
                            </span>
                            <span className="block text-sm text-dark-blue font-medium">
                                {formatMessage(genericMessages.type, { count: 0 })} : {type}
                            </span>
                        </>
                    )}
                    {[TaskGroupStatus.inProgress, TaskGroupStatus.toClose, TaskGroupStatus.done].includes(status) &&
                        Number(workedTime) > 0 && (
                            <>
                                <span className="block text-sm text-black">
                                    {`${formatMessage(genericMessages.time)} : `}
                                    <FormattedDuration value={workedTime ?? 0}></FormattedDuration>
                                </span>
                            </>
                        )}
                </>
            );
        };

        const deduplicatedMergedEvents: MergedEvent[] = extendedProps.mergedEvents?.reduce(
            (acc: MergedEvent[], current: MergedEvent) => {
                const duplicateDate = acc.some((range) => range.startDate === current.startDate);
                if (!duplicateDate) {
                    acc.push(current);
                }
                return acc;
            },
            []
        );

        const getFlexPropertyFromEventDuration = (duration: number) => {
            if (duration === 0.5) {
                return deduplicatedMergedEvents.filter((mergedEvent) => mergedEvent.duration === 1).length >= 1
                    ? 'flex-0.5'
                    : 'flex-1';
            }
            return 'flex-1';
        };

        return (
            <div style={{ height: 16 }} className="flex w-full">
                {deduplicatedMergedEvents.map((mergedEvent: MergedEvent) => (
                    <Tooltip
                        key={mergedEvent.id}
                        title={() => tooltipTitle(mergedEvent)}
                        overlayInnerStyle={{ borderRadius: 2, padding: '12px 16px' }}
                        color="#ffffff"
                    >
                        <div className={getFlexPropertyFromEventDuration(mergedEvent.duration || 1)}></div>
                    </Tooltip>
                ))}
            </div>
        );
    };

    return (
        <Card className="custom-fullcalendar border-t-0 custom-fullcalendar__planning">
            <CustomCalendarTimeline
                dateClick={onClickDate}
                activeDate={activeDate}
                initialView={activeView}
                resizeKey={tab}
                events={OperatorPlanningFormatter(operatorsPlanning)}
                eventSources={operatorsPlanning}
                eventContent={renderEventContent}
                nowIndicator
                resources={resources}
                resourceAreaColumns={resourceAreaColumns}
                slotDuration="24:00"
                slotLabelFormat={{
                    weekday: 'short',
                    day: 'numeric',
                    month: '2-digit',
                }}
            />
        </Card>
    );
};

export default PlanningsOperators;
