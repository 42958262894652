import { compile } from 'path-to-regexp';

import { debug } from './helpers';
import { ManagementOperatorsDetailsTabs } from './pages/management/operators';
import { ManagementTaskGroupsTabs } from './pages/management/taskGroups';
import { PlanningsTabs } from './pages/management/plannings/ManagementPlannings';
import { ManagementVehiclesDetailsTabs } from './pages/management/vehicles';
import { CustomerDetailsTabs } from './pages/settings/customers';

export enum RoutePathName {
    usersList = 'usersList',
    usersDetails = 'usersDetails',
    home = 'home',
    login = 'login',
    notFound = 'notFound',
    privacy = 'privacy',
    forgottenPassword = 'forgottenPassword',
    resetPassword = 'resetPassword',

    // MOBILIC ENDPOINT
    callback = 'callback',

    // Super Admin
    superAdminApplications = 'superAdminApplications',
    superAdminOrganizations = 'superAdminOrganizations',
    superAdminValueList = 'superAdminValueList',
    superAdminRoleList = 'superAdminRoleList',
    // superAdminJobList = 'superAdminJobList',
    superAdminUserList = 'superAdminUserList',

    // Configuration
    vehicles = 'vehicles',
    vehicleDetails = 'vehicleDetails',
    vehiclesDisabled = 'vehiclesDisabled',
    vehiclesValueLists = 'vehiclesValueLists',

    customers = 'customers',
    customerCreate = 'customerCreate',
    customerDetails = 'customerDetails',
    customersDisabled = 'customersDisabled',

    operators = 'operators',
    operatorDetails = 'operatorDetails',
    operatorsDisabled = 'operatorsDisabled',
    operatorsValueLists = 'operatorsValueLists',
    operatorsProfileForm = 'operatorsProfileForm',

    transporters = 'transporters',
    transporterCreate = 'transporterCreate',
    transporterDetails = 'transporterDetails',
    transportersDisabled = 'transportersDisabled',
    transportersValueLists = 'transportersValueLists',

    places = 'places',
    placeDetails = 'placeDetails',
    placesDisabled = 'placesDisabled',
    placesValueLists = 'placesValueLists',

    // Pilotage
    managementVehicles = 'managementVehicles',
    managementVehiclesDetails = 'managementVehiclesDetails',

    managementOperators = 'managementOperators',
    managementOperatorsDetails = 'managementOperatorsDetails',

    managementForecasts = 'managementForecasts',

    managementTaskGroups = 'managementTaskGroups',

    managementPlannings = 'managementPlannings',

    managementIssue = 'managementIssue',

    // Pré-facturation
    preBillingCustomersList = 'preBillingCustomersList',
    preBillingCustomersDetails = 'preBillingCustomersDetails',
    preBillingCustomersTaskGroupServices = 'preBillingCustomersTaskGroupServices',

    preBillingTransportersList = 'preBillingTransportersList',
    preBillingTransportersDetails = 'preBillingTransportersDetails',
    preBillingTransportersTaskGroupServices = 'preBillingTransportersTaskGroupServices',
}

export type Routes = {
    [r in RoutePathName]: string;
};

export const routes: Routes = {
    [RoutePathName.callback]: '/callback',
    [RoutePathName.usersList]: '/users',
    [RoutePathName.usersDetails]: '/users/details/:userId?',
    [RoutePathName.home]: '/',
    [RoutePathName.login]: '/login',
    [RoutePathName.notFound]: '/404',
    [RoutePathName.privacy]: '/privacy',
    [RoutePathName.forgottenPassword]: '/forgotten-password',
    [RoutePathName.resetPassword]: '/reset-password',
    // enum based param example, any param that does not match the enum will result in a 404
    // [RoutePathName.test]: `/test/:param(${Object.values(RoutePathName).join('|')})`,

    [RoutePathName.superAdminOrganizations]: '/super-admin/organizations',
    [RoutePathName.superAdminApplications]: '/super-admin/applications',
    [RoutePathName.superAdminValueList]: '/super-admin/values-lists',
    [RoutePathName.superAdminRoleList]: '/super-admin/roles',
    // [RoutePathName.superAdminJobList]: '/super-admin/jobs',
    [RoutePathName.superAdminUserList]: '/super-admin/users',

    // Configuration
    [RoutePathName.vehicles]: '/vehicles',
    [RoutePathName.vehicleDetails]: '/vehicles/details/:vehicleId?',
    [RoutePathName.vehiclesDisabled]: '/vehicles/disabled',
    [RoutePathName.vehiclesValueLists]: '/vehicles/value-lists',

    [RoutePathName.customers]: '/customers',
    [RoutePathName.customerCreate]: '/customers/create/:step?',
    [RoutePathName.customerDetails]: `/customers/details/:customerId/:tab(${Object.values(CustomerDetailsTabs).join(
        '|'
    )})`,
    [RoutePathName.customersDisabled]: '/customers/disabled',

    [RoutePathName.operators]: '/operators',
    [RoutePathName.operatorDetails]: '/operators/details/:operatorId?',
    [RoutePathName.operatorsDisabled]: '/operators/disabled',
    [RoutePathName.operatorsValueLists]: '/operators/value-lists',
    [RoutePathName.operatorsProfileForm]: '/operators/value-lists/form-profile/:valueListItemId?',

    [RoutePathName.transporters]: '/transporters',
    [RoutePathName.transporterDetails]: '/transporters/details/:transporterId',
    [RoutePathName.transporterCreate]: '/transporters/create/:step?',
    [RoutePathName.transportersDisabled]: '/transporters/disabled',
    [RoutePathName.transportersValueLists]: '/transporters/value-lists',

    [RoutePathName.places]: '/places',
    [RoutePathName.placeDetails]: '/places/details/:placeId?',
    [RoutePathName.placesDisabled]: '/places/disabled',
    [RoutePathName.placesValueLists]: '/places/value-lists',

    // Pilotage
    [RoutePathName.managementVehicles]: '/management/vehicles',
    [RoutePathName.managementVehiclesDetails]: `/management/vehicles/:vehicleId/:tab(${Object.values(
        ManagementVehiclesDetailsTabs
    ).join('|')})`,
    [RoutePathName.managementIssue]: `/management/vehicles/:vehicleId/issue`,

    [RoutePathName.managementOperators]: '/management/operators',
    [RoutePathName.managementOperatorsDetails]: `/management/operators/:operatorId/:tab(${Object.values(
        ManagementOperatorsDetailsTabs
    ).join('|')})`,

    [RoutePathName.managementForecasts]: '/management/forecasts',

    [RoutePathName.managementTaskGroups]: `/management/task-groups/:tab(${Object.values(ManagementTaskGroupsTabs).join(
        '|'
    )})`,

    [RoutePathName.managementPlannings]: `/management/plannings/:tab(${Object.values(PlanningsTabs).join('|')})`,

    // Pré-facturation
    [RoutePathName.preBillingCustomersList]: '/pre-billing/customers',
    [RoutePathName.preBillingCustomersDetails]: '/pre-billing/customers/:customerId',
    [RoutePathName.preBillingCustomersTaskGroupServices]: '/pre-billing/customers/:customerId/task-groups/:taskGroupId',

    [RoutePathName.preBillingTransportersList]: '/pre-billing/transporters',
    [RoutePathName.preBillingTransportersDetails]: '/pre-billing/transporters/:transporterId',
    [RoutePathName.preBillingTransportersTaskGroupServices]:
        '/pre-billing/transporters/:transporterId/task-groups/:taskGroupId',
};

export interface RouteParams {
    [param: string]: string | number;
}

// returns raw react-router string path eg: /user/:id
export const getRawRoute = (path: RoutePathName) => {
    if (!routes[path]) {
        debug.error(`[getRawRoute] Route not found for path "${path}", returning /404.`);
        return '/404';
    } else {
        return routes[path];
    }
};

// returns real-world path eg: /user/1337
export const getRoute = (
    path: RoutePathName,
    params?: RouteParams,
    queryParams?: string | string[][] | Record<string, string> | URLSearchParams | undefined,
    anchor?: string
) => {
    let route = getRawRoute(path);
    const compiler = compile(route, { encode: encodeURIComponent });

    try {
        route = compiler(params);
    } catch (error) {
        debug.error('[getRoute] route compile error :', error);
    }

    if (queryParams && Object.keys(queryParams).length) {
        const urlQueryParams = new URLSearchParams(queryParams);
        route += `?${urlQueryParams.toString()}`;
    }

    if (anchor) {
        route = `${route}#${anchor}`;
    }

    return route;
};
