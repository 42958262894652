import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Col, Form, Modal, ModalProps, Row } from 'antd';
import { useIntl } from 'react-intl';

import genericMessages from '../i18n/genericMessages';
import TaskGroupVehicleSelector from '../pages/management/taskGroups/TaskGroupVehicleSelector';
import { useTaskGroupListAvailableVehicles } from '../queries/taskGroups';

interface SelectVehiculeModalProps
    extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>,
        Record<string, any> {
    title?: string;
    isLoading?: boolean;
    cancelText?: string;
    confirmText?: string;
    taskGroupId: string;
}
const SelectVehiculeModal = NiceModal.create<SelectVehiculeModalProps>(
    ({ title, isLoading, cancelText, confirmText, taskGroupId, ...props }) => {
        const { formatMessage } = useIntl();
        const selectModal = useModal();
        const [form] = Form.useForm<{ vehicleId: string }>();
        const vehicleId = Form.useWatch('vehicleId', form);
        const { data: availableVehicles } = useTaskGroupListAvailableVehicles({
            id: taskGroupId,
            pageSize: 999,
        });

        return (
            <Modal
                {...props}
                {...antdModal(selectModal)}
                afterClose={selectModal.remove}
                closable={false}
                footer={false}
                centered
            >
                <Row>
                    <Col span={22}>
                        <p className="text-lg font-bold text-blue">{title}</p>
                        <Form name="vehiculeForm" form={form}>
                            <Form.Item name="vehicleId" required={true}>
                                <TaskGroupVehicleSelector
                                    vehicles={availableVehicles}
                                    placement="bottom"
                                    vehicleId={vehicleId}
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col span={24} className="flex justify-end gap-2">
                        <Button key="cancelBtn" onClick={selectModal.hide}>
                            {cancelText || formatMessage(genericMessages.cancel)}
                        </Button>
                        <Button
                            key="yesBtn"
                            onClick={() => selectModal.resolve(vehicleId)}
                            type="primary"
                            loading={isLoading}
                            disabled={vehicleId === undefined}
                        >
                            {confirmText || formatMessage(genericMessages.validate)}
                        </Button>
                    </Col>
                </Row>
            </Modal>
        );
    }
);
export default SelectVehiculeModal;
