import { FC, useCallback, useEffect } from 'react';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';

import useQueryParams from '../../hooks/queryParams';
// import constants from '../../config/constants';
import { useAuthorizeMobilic } from '../../queries/mobilic';
import { errorMessage, successMessage } from '../../helpers/message';
import { defaultErrorMessage } from '../../i18n';
import { usePlaceUpdateAdminMobilicToken } from '../../queries/places';
import { getRoute, RoutePathName } from '../../routes';
import usePersistentState from '../../hooks/usePersistentState';
// import { usePlaceUpdate } from '../../queries/places';

const CodeValidation: FC = () => {
    const [queryParams] = useQueryParams('code');
    const code = queryParams.get('code');
    const history = useHistory();
    // const mobilicHttpClient = axios.create({
    //     baseURL: constants.MOBILIC_TOKEN_URI,
    // });
    const { mutate: mutateAuthorizeMobilic, isSuccess, isLoading, isError } = useAuthorizeMobilic();
    const { mutate: updatPlaceAdminMobilicToken } = usePlaceUpdateAdminMobilicToken();
    const [updatingPlaceId, setUpdatingPlaceId] = usePersistentState('updatingPlaceId');

    const authorizeMobilic = useCallback(() => {
        if (code && updatingPlaceId) {
            mutateAuthorizeMobilic(code, {
                onSuccess: ({ access_token }) => {
                    updatPlaceAdminMobilicToken(
                        { id: updatingPlaceId, adminMobilicToken: access_token },
                        {
                            onSuccess: () => {
                                setUpdatingPlaceId();
                                successMessage({ content: 'Token Mobilic généré avec succès' }); // TODO i18n
                                history.push(getRoute(RoutePathName.placeDetails, { placeId: updatingPlaceId }));
                            },
                            onError: () => {
                                errorMessage({ content: defaultErrorMessage });
                            },
                        }
                    );
                },
                onError: () => {
                    setUpdatingPlaceId();
                    errorMessage({ content: defaultErrorMessage });
                    history.push(getRoute(RoutePathName.placeDetails, { placeId: updatingPlaceId }));
                },
            });
        } else {
            errorMessage({ content: 'Pas de magasin à mettre à jour avec le token mobilic' });
        }
    }, [code, history, mutateAuthorizeMobilic, setUpdatingPlaceId, updatPlaceAdminMobilicToken, updatingPlaceId]);

    useEffect(() => {
        if (!isLoading && !isSuccess && !isError) {
            authorizeMobilic();
        }
        // mobilicHttpClient
        //     .post(
        //         '/token',
        //         qs.stringify({
        //             grant_type: 'authorization_code',
        //             code: code,
        //             redirect_uri: constants.MOBILIC_REDIRECT_URI,
        //             client_id: constants.MOBILIC_CLIENT_ID,
        //             client_secret: constants.MOBILIC_CLIENT_SECRET,
        //         }),
        //         {
        //             headers: {
        //                 'Content-Type': 'application/x-www-form-urlencoded',
        //             },
        //         }
        //     )
        //     .then(() => {
        //         /* placeUpdate(data) */
        //     })
        //     .catch(console.log);
    });

    // return <>TEST + {code}</>;
    return <Spin spinning={true} />;
};

export default CodeValidation;
