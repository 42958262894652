export default Object.freeze({
    PAGE_SIZE: 50,
    API: Object.freeze({
        BASE_URL: process.env.REACT_APP_API_BASE_URL || '/api',
        KEY: process.env.REACT_APP_API_KEY || '',
    }),
    SEO: Object.freeze({
        BASE_URL: '',
        SITE_NAME: 'ID Logistics - LAD',
        DEFAULT_TITLE: 'ID Logistics - LAD',
        DEFAULT_DESCRIPTION: 'LAD Backoffice',
    }),
    GOOGLE_ANALYTICS: process.env.REACT_APP_GOOGLE_ANALYTICS || '',
    ENABLE_DEBUG_LOGS: false, // process.env.NODE_ENV === 'production',
    DEBUG_LOGS_APP: '', // <app>_<service>
    VERSION: process.env.REACT_APP_VERSION || '',
    FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID || '',
    NOTIFICATIONS_REFETCH_INTERVAL_IN_MS: 5000,
    PASSWORD_MINIMUM_LENGTH: 10,
    HEADER_PRODUCT_NAME: 'Livraison à domicile',
    MAPBOX_TOKEN:
        process.env.REACT_APP_MAPBOX_TOKEN ||
        'pk.eyJ1Ijoic3RzcGVubGUiLCJhIjoiY2w3NmF0eXZhMDl3czNxcGc3cmg2d2gzZSJ9.jGz07O1v3eVBQzIQPtGm2Q',
    MAPBOX_STYLE: 'mapbox://styles/stspenle/cl76ccynn000w14pbivbfkow6',
    COORDINATES: Object.freeze({
        fr: Object.freeze({
            latitude: 46.71109,
            longitude: 1.7191036,
        }),
        en: Object.freeze({
            latitude: 55.3617609,
            longitude: -3.4433238,
        }),
    }),
    FULLCALENDAR_LICENSE_KEY: process.env.REACT_APP_FULLCALENDAR_LICENSE_KEY,
    API_MOBILIC: Object.freeze({
        REDIRECT_URI: process.env.REACT_APP_MOBILIC_REDIRECT_URI ?? 'https://dev.idlogistics.onefirst.dev/callback',
        BASE_URL_AUTHORIZATION: process.env.REACT_APP_MOBILIC_OAUTH_URI || 'https://sandbox.mobilic.beta.gouv.fr/oauth',
        BASE_URL_TOKEN:
            process.env.REACT_APP_MOBILIC_TOKEN_URI || 'https://sandbox.mobilic.beta.gouv.fr/api/oauth/token',
        CLIENT_ID: process.env.REACT_APP_MOBILIC_CLIENT_ID || '335677708',
        CLIENT_SECRET:
            process.env.REACT_APP_MOBILIC_CLIENT_SECRET ||
            'fae5ca74c4c543e3c9aab94e0baf3012b67e4c10ed491565c500aa83b08c0903205d489db7d06221d7810248cb629827aa14ed2b986a2cb0c9cf69e1',
    }),

    // MOBILIC_OAUTH_URI: process.env.REACT_APP_MOBILIC_OAUTH_URI ?? 'https://sandbox.mobilic.beta.gouv.fr/oauth ',
    // MOBILIC_CLIENT_ID: process.env.REACT_APP_MOBILIC_CLIENT_ID ?? '335677708',
    // MOBILIC_REDIRECT_URI: process.env.REACT_APP_MOBILIC_REDIRECT_URI ?? 'https://localhost:5173/code',
    // MOBILIC_TOKEN_URI: process.env.REACT_APP_MOBILIC_TOKEN_URI ?? 'https://sandbox.mobilic.beta.gouv.fr/api/oauth',
    // MOBILIC_CLIENT_SECRET:
    //     process.env.REACT_APP_MOBILIC_CLIENT_SECRET ??
    //     'fae5ca74c4c543e3c9aab94e0baf3012b67e4c10ed491565c500aa83b08c0903205d489db7d06221d7810248cb629827aa14ed2b986a2cb0c9cf69e1',
});
