import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

import { authorizeMobilic, MobilicAuthorizeResponse } from './api/apiMobilic';

// export const jobKeys = {
//     all: ['jobs'],
// };

export const useAuthorizeMobilic = (options?: UseMutationOptions<MobilicAuthorizeResponse, AxiosError, string>) => {
    // const queryClient = useQueryClient();
    return useMutation<MobilicAuthorizeResponse, AxiosError, string>(async (params) => await authorizeMobilic(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options?.onSettled?.(data, error, variables, context);

            // invalidate application list query to refetch with the newly added item
            // queryClient.invalidateQueries(jobKeys.lists());
        },
    });
};
