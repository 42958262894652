import { useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import {
    Button,
    Card,
    Descriptions,
    Form,
    FormProps,
    Segmented,
    Space,
    Spin,
    Typography,
    Upload,
    UploadFile,
} from 'antd';
import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { serialize } from 'object-to-formdata';
import TextArea from 'antd/lib/input/TextArea';

import { getRoute, RoutePathName } from '../../../routes';
import vehicleMessages from '../../../i18n/vehicleMessages';
import ListTitle from '../../../components/ListTitle';
import ApiResult from '../../../components/ApiResult';
import { IssueCriticityLevel, IssueStatus, ValueListSlug, Vehicle } from '../../../queries/api/types';
import { useVehicleDetails, useVehicleIssueCreate } from '../../../queries/vehicles';
import { ManagementVehiclesDetailsTabs } from './';
import { useValueListItemList } from '../../../queries/valueListItems';
import formMessages from '../../../i18n/formMessages';
import { VehicleIssueCreatePayload, VehicleIssueCreatePayloadFormData } from '../../../queries/api/vehicles';
import { errorMessage, successMessage } from '../../../helpers/message';
import genericMessages from '../../../i18n/genericMessages';
import { truthy } from '../../../globalTypes';
import { requiredRule } from '../../../helpers';
import { issueCriticityLevelMessageMap } from '../../../i18n/issueMessages';

const ManagementVehicleIssue: VFC = () => {
    const { vehicleId } = useParams<{ vehicleId: Vehicle['id'] }>();
    const {
        data: vehicleDetails,
        isError,
        error,
        isSuccess: isVehicleSuccess,
    } = useVehicleDetails(vehicleId, { enabled: !!vehicleId });
    const [form] = Form.useForm();
    const { formatMessage } = useIntl();
    const { data: issueCategories, isSuccess: isCategorySuccess } = useValueListItemList({
        valueListSlug: ValueListSlug.issueCategories,
    });
    const { data: issueSubCategories, isSuccess: isSubCategorySuccess } = useValueListItemList({
        valueListSlug: ValueListSlug.issueSubCategories,
    });
    const [uploadFile, setUploadFile] = useState<UploadFile[]>([]);
    const { mutate: createIssue } = useVehicleIssueCreate();
    const category = Form.useWatch('category', form);
    const selectedCategory = issueCategories?.items?.find((c) => c.id === category);

    const handleBeforeUpload = (file: RcFile) => {
        setUploadFile((uploadFile) => [
            ...uploadFile,
            {
                uid: uuidv4(),
                name: file.name,
                thumbUrl: URL.createObjectURL(file),
                originFileObj: file,
            },
        ]);
        return false;
    };
    const handleOnRemove = (file: UploadFile) => {
        setUploadFile((uploadFile) => uploadFile.filter((f) => f.uid !== file.uid));
    };

    const onSubmit: FormProps<VehicleIssueCreatePayload>['onFinish'] = (values) => {
        const payload: VehicleIssueCreatePayload = {
            ...values,
            status: IssueStatus.opened,
            date: dayjs().format('YYYY-MM-DD'),
            reference: uuidv4(),
            files: uploadFile.map((file) => file.originFileObj).filter(truthy),
        };
        const formDataPayload: VehicleIssueCreatePayloadFormData = {
            id: vehicleId,
            payload: serialize(payload, { indices: false }),
        };
        createIssue(formDataPayload, {
            onSuccess: () => {
                form.resetFields();
                setUploadFile([]);
                successMessage({
                    content: formatMessage(genericMessages.add),
                });
            },
            onError: (error) => {
                errorMessage({
                    content: formatMessage(genericMessages.defaultErrorWithStatus, {
                        status: error.response?.status,
                    }),
                });
            },
        });
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="mt-2">
                <FormattedMessage {...formMessages.add} />
            </div>
        </div>
    );

    return isError ? (
        <ApiResult status={error.response?.status} />
    ) : isVehicleSuccess && isCategorySuccess && isSubCategorySuccess ? (
        <>
            <Space direction="vertical" size="middle" style={{ width: '100%' }} className={'!flex justify-center'}>
                <div className="flex justify-between items-center space-x-2">
                    <ListTitle
                        className="mb-0 uppercase"
                        backRoute={getRoute(RoutePathName.managementVehiclesDetails, {
                            vehicleId: vehicleId,
                            tab: ManagementVehiclesDetailsTabs.history,
                        })}
                    >
                        <FormattedMessage {...vehicleMessages.issueDetailVehiculeDeclare} />
                    </ListTitle>
                </div>
                <Card>
                    <div className="flex justify-between items-center mb-6">
                        <Typography.Title level={3} className="text-blue font-bold">
                            <FormattedMessage {...vehicleMessages.issueDetailTitle} />
                        </Typography.Title>
                    </div>
                    <div>
                        <Descriptions
                            column={1}
                            colon={false}
                            labelStyle={{ width: 316, justifyContent: 'flex-end', textAlign: 'right' }}
                            key={'vehicleName'}
                        >
                            <Descriptions.Item
                                className="ant-form-item-label"
                                label={<label>{formatMessage(vehicleMessages.issueDetailVehicleName)}</label>}
                            >
                                {`${vehicleDetails?.brand.fields.brand} ${vehicleDetails?.model.fields.model}`}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                            column={1}
                            colon={false}
                            labelStyle={{ width: 316, justifyContent: 'flex-end', textAlign: 'right' }}
                            key={'parcNumber'}
                        >
                            <Descriptions.Item label={formatMessage(vehicleMessages.issueDetailVehicleParcNumber)}>
                                {vehicleDetails?.reference}
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                            column={1}
                            colon={false}
                            labelStyle={{ width: 316, justifyContent: 'flex-end', textAlign: 'right' }}
                            key={'plateNumber'}
                        >
                            <Descriptions.Item label={formatMessage(vehicleMessages.plateNumber)}>
                                {vehicleDetails?.plateNumber}
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                </Card>
                <Form
                    form={form}
                    initialValues={{
                        category: issueCategories?.items.at(0)?.id,
                        subCategory: issueSubCategories?.items.at(0)?.id,
                        criticityLevel: Object.values(IssueCriticityLevel).at(0),
                    }}
                    onFinish={onSubmit}
                >
                    <Card>
                        <div className="flex justify-between items-center mb-6">
                            <Typography.Title level={3} className="text-blue font-bold">
                                <FormattedMessage {...vehicleMessages.issueDetailVehicleIncident} />
                            </Typography.Title>
                        </div>
                        <div>
                            <Descriptions
                                column={1}
                                colon={false}
                                labelStyle={{ width: 316, justifyContent: 'flex-end', textAlign: 'right' }}
                                key={'category'}
                            >
                                <Descriptions.Item
                                    label={`${formatMessage(vehicleMessages.issueDetailVehicleCategory)} *`}
                                    className={'vertical-align-description'}
                                >
                                    <Form.Item
                                        name={'category'}
                                        rules={[requiredRule]}
                                        label={`${formatMessage(vehicleMessages.issueDetailVehicleCategory)} *`}
                                        noStyle
                                    >
                                        <Segmented
                                            options={
                                                issueCategories?.items.map(({ id, fields }) => ({
                                                    label: fields.category,
                                                    value: id,
                                                })) ?? []
                                            }
                                        />
                                    </Form.Item>
                                </Descriptions.Item>
                            </Descriptions>
                            {selectedCategory?.fields.category === 'Carrosserie' && (
                                <Descriptions
                                    column={1}
                                    colon={false}
                                    labelStyle={{ width: 316, justifyContent: 'flex-end', textAlign: 'right' }}
                                    key={'subCategory'}
                                >
                                    <Descriptions.Item
                                        label={`${formatMessage(vehicleMessages.issueDetailVehicleSubCategory)} *`}
                                        className={'vertical-align-description'}
                                    >
                                        <Form.Item name={'subCategory'} rules={[requiredRule]} noStyle>
                                            <Segmented
                                                options={
                                                    issueSubCategories?.items.map(({ id, fields }) => ({
                                                        label: fields.subCategory,
                                                        value: id,
                                                    })) ?? []
                                                }
                                            />
                                        </Form.Item>
                                    </Descriptions.Item>
                                </Descriptions>
                            )}
                            <Descriptions
                                column={1}
                                colon={false}
                                labelStyle={{ width: 316, justifyContent: 'flex-end', textAlign: 'right' }}
                                key={'criticityLevel'}
                            >
                                <Descriptions.Item
                                    label={`${formatMessage(vehicleMessages.issueDetailVehicleState)} *`}
                                    className={'vertical-align-description'}
                                >
                                    <Form.Item name={'criticityLevel'} rules={[requiredRule]} noStyle>
                                        <Segmented
                                            options={Object.values(IssueCriticityLevel).map((level) => ({
                                                label: formatMessage(issueCriticityLevelMessageMap.get(level)!),
                                                value: level,
                                            }))}
                                        />
                                    </Form.Item>
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions
                                column={1}
                                colon={false}
                                labelStyle={{ width: 316, justifyContent: 'flex-end', textAlign: 'right' }}
                                key={'files'}
                            >
                                <Descriptions.Item
                                    label={`${formatMessage(vehicleMessages.issueDetailVehiclePicture)} *`}
                                    className={'vertical-align-description'}
                                >
                                    <Form.Item name={'files'} rules={[requiredRule]} noStyle>
                                        <Upload
                                            beforeUpload={handleBeforeUpload}
                                            fileList={uploadFile}
                                            listType={'picture-card'}
                                            accept={'image/*'}
                                            onRemove={handleOnRemove}
                                        >
                                            {uploadFile.length >= 8 ? null : uploadButton}
                                        </Upload>
                                    </Form.Item>
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions
                                column={1}
                                colon={false}
                                labelStyle={{ width: 316, justifyContent: 'flex-end', textAlign: 'right' }}
                                key={'description'}
                            >
                                <Descriptions.Item
                                    label={`${formatMessage(vehicleMessages.issueDetailVehicleComment)} *`}
                                    className={'vertical-align-description'}
                                >
                                    <Form.Item name={'description'} rules={[requiredRule]} noStyle>
                                        <TextArea
                                            autoSize={{ minRows: 4 }}
                                            style={{
                                                maxWidth: '360px',
                                            }}
                                            placeholder=""
                                        />
                                    </Form.Item>
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                    </Card>
                </Form>
                <div className="flex justify-center">
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => {
                            form.submit();
                        }}
                    >
                        <CheckOutlined />
                        {formatMessage(vehicleMessages.issueDetailVehicleSubmit)}
                    </Button>
                </div>
            </Space>
        </>
    ) : (
        <Spin />
    );
};

export default ManagementVehicleIssue;
