import { useModal } from '@ebay/nice-modal-react';
import { Button, Card, Descriptions, List, Tooltip, Typography } from 'antd';
import { CardTabListType } from 'antd/lib/card';
import { useMemo, useState, VFC } from 'react';
import { HiOutlineQrcode, HiOutlineSwitchHorizontal } from 'react-icons/hi';
import { FormattedMessage, FormattedNumber, FormattedTime, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import SelectVehiculeModal from '../../../components/SelectVehiculeModal';
import { getFullName } from '../../../helpers';
import { errorMessage, successMessage } from '../../../helpers/message';
import genericMessages from '../../../i18n/genericMessages';
import vehicleMessages from '../../../i18n/vehicleMessages';
import { TaskGroup } from '../../../queries/api/types';
import { useTaskGroupDetails, useTaskGroupUpdate } from '../../../queries/taskGroups';
import { getRoute, RoutePathName } from '../../../routes';
import { ManagementOperatorsDetailsTabs } from '../operators';
import { ManagementVehiclesDetailsTabs } from '../vehicles';

enum TaskGroupDetailsTabs {
    deliveries = 'deliveries',
    operator = 'operator',
    vehicle = 'vehicle',
}

interface TaskGroupDetailsTabsCardProps {
    taskGroupId?: TaskGroup['id'];
    hideModal: () => void;
}
const changeVehiculeModalId = 'change-vehicule-modal';

const TaskGroupDetailsTabsCard: VFC<TaskGroupDetailsTabsCardProps> = ({ taskGroupId, hideModal }) => {
    const { formatMessage } = useIntl();
    const { data: taskGroup } = useTaskGroupDetails(taskGroupId, { enabled: !!taskGroupId });
    const [activeTabKey, setActiveTabKey] = useState(TaskGroupDetailsTabs.deliveries);
    const changeVehiculeModal = useModal(changeVehiculeModalId);
    const { mutate: updateTaskGroup } = useTaskGroupUpdate();
    const tabList = useMemo<CardTabListType[]>(
        () => [
            {
                key: TaskGroupDetailsTabs.deliveries,
                tab: formatMessage(genericMessages.deliveries, { count: 2 }),
            },
            {
                key: TaskGroupDetailsTabs.operator,
                tab: formatMessage(genericMessages.operator),
                disabled: !taskGroup?.operator || !!taskGroup.vehicle?.transporter,
            },
            {
                key: TaskGroupDetailsTabs.vehicle,
                tab: formatMessage(genericMessages.vehicles, { count: 1 }),
                disabled: !taskGroup?.vehicle,
            },
        ],
        [formatMessage, taskGroup?.operator, taskGroup?.vehicle]
    );

    const handleVehiculeChange = () => {
        changeVehiculeModal.show().then((vehiculeId) => {
            const id = vehiculeId as string;
            updateTaskGroup(
                {
                    id: taskGroupId,
                    vehicle: id,
                },
                {
                    onSuccess: () => {
                        changeVehiculeModal.hide();
                        successMessage({
                            content: formatMessage(vehicleMessages.updateVehiculeSuccessMsg),
                        });
                    },
                    onError: () => {
                        errorMessage({
                            content: formatMessage(genericMessages.defaultError),
                        });
                    },
                }
            );
        });
    };

    return (
        <Card
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={(key) => {
                setActiveTabKey(key as TaskGroupDetailsTabs);
            }}
        >
            {activeTabKey === TaskGroupDetailsTabs.deliveries && (
                <List
                    dataSource={taskGroup?.tasks}
                    itemLayout="vertical"
                    renderItem={(task) => (
                        <List.Item>
                            <Typography.Paragraph className="text-blue font-semibold text-base mb-3">
                                {task.recipient || '-'}
                            </Typography.Paragraph>
                            <Descriptions column={1} colon={false} labelStyle={{ width: 160 }} size="small">
                                <Descriptions.Item
                                    label={formatMessage({
                                        id: 'task.scheduled_arrival_time',
                                        defaultMessage: 'Arrivée prévue',
                                    })}
                                >
                                    <FormattedTime value={task.scheduledAt} />
                                </Descriptions.Item>
                                <Descriptions.Item label={formatMessage(genericMessages.reference)}>
                                    {task.reference}
                                </Descriptions.Item>
                            </Descriptions>
                        </List.Item>
                    )}
                    bordered
                />
            )}
            {activeTabKey === TaskGroupDetailsTabs.operator && taskGroup && (
                <>
                    <div className="flex items-center justify-between">
                        <Typography.Paragraph className="text-blue font-semibold text-base mb-3">
                            {getFullName(taskGroup?.operator) || '-'}
                        </Typography.Paragraph>
                        <div className="flex items-center gap-2 mb-3">
                            <Link
                                to={getRoute(RoutePathName.managementOperatorsDetails, {
                                    operatorId: taskGroup.operator?.id || '',
                                    tab: ManagementOperatorsDetailsTabs.information,
                                })}
                                onClick={() => {
                                    hideModal();
                                    window.scrollTo({ top: 0 });
                                }}
                            >
                                <Button type="primary" size="small" ghost>
                                    <FormattedMessage {...genericMessages.goToDetails} />
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <Descriptions column={1} colon={false} labelStyle={{ width: 160 }} size="small">
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'task_group.shift_started_at',
                                defaultMessage: 'Prise de poste',
                            })}
                        >
                            {taskGroup.shiftStartedAt ? <FormattedTime value={taskGroup.shiftStartedAt} /> : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'task_group.shift_ended_at',
                                defaultMessage: 'Fin de poste',
                            })}
                        >
                            {taskGroup.shiftEndedAt ? <FormattedTime value={taskGroup.shiftEndedAt} /> : '-'}
                        </Descriptions.Item>
                    </Descriptions>
                </>
            )}
            {activeTabKey === TaskGroupDetailsTabs.vehicle && taskGroup && (
                <>
                    <div className="flex items-center justify-between">
                        <Typography.Paragraph className="text-blue font-semibold text-base mb-3">
                            {taskGroup.vehicle?.reference || '-'}
                        </Typography.Paragraph>
                        <div className="flex items-center gap-2 mb-3">
                            <Link
                                to={getRoute(RoutePathName.managementVehiclesDetails, {
                                    vehicleId: taskGroup.vehicle?.id ?? '',
                                    tab: ManagementVehiclesDetailsTabs.information,
                                })}
                                onClick={() => {
                                    hideModal();
                                    window.scrollTo({ top: 0 });
                                }}
                            >
                                <Button type="primary" size="small">
                                    <FormattedMessage {...genericMessages.goToDetails} />
                                </Button>
                            </Link>
                            <Button type="primary" size="small" ghost onClick={handleVehiculeChange}>
                                <FormattedMessage {...vehicleMessages.change} />
                            </Button>
                        </div>
                    </div>
                    <Descriptions column={1} colon={false} labelStyle={{ width: 180 }} size="small">
                        <Descriptions.Item label={formatMessage(vehicleMessages.plateNumber)}>
                            <div className="flex items-center gap-4">
                                {taskGroup.vehicle?.plateNumber || '-'}
                                {taskGroup.computedProperties?.operatorLoggedInWithQRCode && (
                                    <Tooltip
                                        title={formatMessage({
                                            id: 'task_group.operator_logged_in_with_qr_code.tooltip',
                                            defaultMessage: "Le chauffeur s'est connecté avec le QR code",
                                        })}
                                    >
                                        <HiOutlineQrcode className="text-xl text-green" />
                                    </Tooltip>
                                )}
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'task_group.vehicle_starting_mileage',
                                defaultMessage: 'Kilométrage départ',
                            })}
                        >
                            {taskGroup.computedProperties?.vehicleStartingMileage ? (
                                <FormattedNumber
                                    value={taskGroup.computedProperties?.vehicleStartingMileage}
                                    style="unit"
                                    unit="kilometer"
                                />
                            ) : (
                                '-'
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'task_group.vehicle_ending_mileage',
                                defaultMessage: "Kilométrage d'arrivée",
                            })}
                        >
                            {taskGroup.computedProperties?.vehicleEndingMileage ? (
                                <FormattedNumber
                                    value={taskGroup.computedProperties?.vehicleEndingMileage}
                                    style="unit"
                                    unit="kilometer"
                                />
                            ) : (
                                '-'
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'task_group.vehicle.open_incidents_count',
                                defaultMessage: 'Incidents ouverts',
                            })}
                        >
                            {taskGroup.computedProperties?.vehicleOpenedIssues !== undefined ? (
                                <FormattedNumber value={taskGroup.computedProperties?.vehicleOpenedIssues} />
                            ) : (
                                '-'
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'task_group.vehicle.incidents_during_task_group',
                                defaultMessage: 'Incidents pdt l’OT',
                            })}
                        >
                            {taskGroup.computedProperties?.vehicleOpenedIssuesDuringTaskGroup !== undefined ? (
                                <FormattedNumber
                                    value={taskGroup.computedProperties?.vehicleOpenedIssuesDuringTaskGroup}
                                />
                            ) : (
                                '-'
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'task_group.ending_place',
                                defaultMessage: 'Entrepôt de retour',
                            })}
                        >
                            <div className="flex items-center gap-4">
                                {taskGroup.endingPlace?.name ?? '-'}
                                {taskGroup.computedProperties?.endingPlaceIsDifferentFromStartingPlace && (
                                    <Tooltip
                                        title={formatMessage({
                                            id: 'task_group.ending_place_different_than_starting_place.tooltip',
                                            defaultMessage: 'Arrivé dans un entrepôt/magasin différent',
                                        })}
                                    >
                                        <HiOutlineSwitchHorizontal className="text-xl text-faded-blue" />
                                    </Tooltip>
                                )}
                            </div>
                        </Descriptions.Item>
                    </Descriptions>
                </>
            )}
            {taskGroup && (
                <SelectVehiculeModal
                    title={formatMessage(vehicleMessages.change)}
                    id={changeVehiculeModalId}
                    taskGroupId={taskGroup.id}
                    closable={true}
                />
            )}
        </Card>
    );
};

export default TaskGroupDetailsTabsCard;
